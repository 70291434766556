import React from "react";
import Layout from "../components/layout";
import { useState } from "react";
import { ReactComponent as Mail } from "../assets/imgs/mail.svg";
import { ReactComponent as Phone } from "../assets/imgs/phone.svg";
import { ReactComponent as Marker } from "../assets/imgs/marker.svg";
import axios from "axios";

import Img from "gatsby-image";

import { useIntl } from "gatsby-plugin-intl";
import { useEffect } from "react";
import SEO from "../components/seo";

const API_PATH = "https://www.apartmentserik.com/api/contact/index.php";

const Kontakt = ({ data, location }) => {
  const intl = useIntl();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [room, setRoom] = useState("");
  const [mailSent, setMailSent] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (location.state && location.state.room) {
      setRoom(location.state.room);
    }
  }, [location.state]);

  // location.state.room
  //   ? console.log(location.state.room)
  //   : console.log("no room")

  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: API_PATH,
      data: {
        name: name,
        email: email,
        message: message,
        room: room,
      },
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    })
      .then((result) => {
        setName("");
        setEmail("");
        setMessage("");
        setMailSent(result.data.sent);
      })
      .catch((error) => setError(error.message));
  };

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "kontakt.naslov" })}
      />
      <div className="section blue-background kontakt">
        <div className="content">
          <div className="card kontakt">
            <h1>{intl.formatMessage({ id: "kontakt.naslov" })}</h1>
            <p>{intl.formatMessage({ id: "kontakt.opis" })}</p>
            <div className="row">
              <div className="col">
                <form onSubmit={handleSubmit} className="col">
                  {room ? (
                    <div className="selected-room">
                      <p>{intl.formatMessage({ id: "kontakt.soba" })} </p>
                      <div className="selected-room-details">
                        <Img
                          fluid={{ ...location.state.slika, aspectRatio: 1 }}
                        />
                        <p>
                          {intl.formatMessage({
                            id: `jedinica${room.id}.naslov`,
                          })}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <label htmlFor="name">
                    {intl.formatMessage({ id: "kontakt.ime" })}
                  </label>
                  <input
                    type="name"
                    name="name"
                    id="name"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="message">
                    {intl.formatMessage({ id: "kontakt.message" })}
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <input
                    type="submit"
                    value={intl.formatMessage({ id: "kontakt.posalji" })}
                  />
                </form>
                {mailSent ? (
                  <div class="mail-sent-notice">
                    <p>{intl.formatMessage({ id: "kontakt.mailSent" })} </p>
                  </div>
                ) : (
                  ""
                )}
                {error ? (
                  <div class="error-notice">
                    {intl.formatMessage({ id: "kontakt.error" })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col">
                <div style={{ width: "100%", marginBottom: "1.5rem" }}>
                  <iframe
                    title="Apartaments Erik Map"
                    width="100%"
                    height="300"
                    src="https://maps.google.com/maps?width=100%&amp;height=500&amp;hl=en&amp;q=Apartments%20and%20Rooms%20%22Erik%22%2C%20Rovinj%2C%20Croatia+(Apartments%20and%20Rooms%20Erik)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  >
                    <a href="https://www.maps.ie/draw-radius-circle-map/">
                      km radius map
                    </a>
                  </iframe>
                </div>
                <a
                  className="email"
                  href="mailto:jelena.jeremic.pavletic@gmail.com"
                >
                  <Mail />
                  jelena.jeremic.pavletic@gmail.com
                </a>
                <a className="tel" href="tel:00385959010526">
                  <Phone />
                  +385(0)959010526
                </a>
                <a href="https://goo.gl/maps/BCgK6HC8SrpRUjvC7" target="_blank">
                  <Marker />
                  Antonija Bronzina 6, 52210 Rovinj - Hrvatska
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Kontakt;
